import React from "react";
import "./Footer.css";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
//import LogoWhite from '../../../images/whitelogo.png';
const Footer = () => {
  const LogoWhite =
    "https://zoplardatabase.s3.ap-southeast-2.amazonaws.com/pageimages/whitelogo.png";
  return (
    <div className="footer-container">
      <div className="aboutcompany">
        <img src={LogoWhite} alt="zoplar logo" />
        <p className="address">
          M3M Cosmopolitan, 2nd Floor, Sector 66
          <br></br>
          Gurugram, Haryana, PIN- 122002
        </p>
      </div>
      <div className="footer-links">
        <ul className="footer-list">
          <li>
            <a href="/AboutUs">About Us</a>
          </li>
          <li>
            <a href="/ContactUs">Contact Us</a>
          </li>
          <li>
            <a href="/categories">Categories</a>
          </li>
          {/* <li>
            <a href="/TnC">Terms & Conditions</a>
          </li> */}
          <li>
            <a href="/privacy">Privacy Policy</a>
          </li>
          <li>
            <a href="/referral">Refer & Earn</a>
          </li>
          <li>
            <a href="/return-cancellation">Return & Cancellation</a>
          </li>
        </ul>
      </div>
      <div className="social-links">
        <p className="follow-us">Follow Us:</p>
        <div className="icons">
          <a
            href="mailto:support@zoplar.com"
            className="icon-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <EmailIcon style={{ color: "#fff" }} />
          </a>
          <a
            href="tel:8000811160"
            className="icon-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CallIcon style={{ color: "#fff" }} />
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=100090193974999"
            className="icon-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookIcon style={{ color: "#fff" }} />
          </a>
          <a
            href="https://www.linkedin.com/company/zoplar/?originalSubdomain=in"
            className="icon-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedInIcon style={{ color: "#fff" }} />
          </a>
          <a
            href="https://www.instagram.com/_zoplar_/"
            className="icon-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramIcon style={{ color: "#fff" }} />
          </a>
          {/* <a href="https://www.youtube.com/@zoplar" className="icon-link">
            <YouTubeIcon style={{ color: "#fff" }} />
          </a> */}
        </div>
      </div>
      <div className="footer-copyright">
        <p><strong>Zoplar</strong> is a registered trademark operated by <strong>Savdo Technologies Pvt. Ltd.</strong>  (U51100HR2022PTC104168)</p>
      </div>
    </div>
  );
};

export default Footer;
